import {
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory,
  type RouteLocation,
} from 'vue-router';
import { useAppStore } from '@@home/stores/app';
import { getInstance } from '@@home/plugins/i18n/index';

import LayoutSimple from '@@home/layouts/simple.vue';


export function createRouter() {
  const locale = getInstance();

  const isZh = locale.global.locale.value === 'zh-CN';

  // 定义路由，注意：不建议使用 name 属性
  // 因为多语言的关系，一定要在 createRouter 内部定义路由

  // meta.title 传递 false 表示标题由组件直接控制，导航守卫不进行干预，如果组件不控制，则标题不变

  const routes = [
    {
      path: '/',
      component: () => import('../views/home/index.vue'),
      meta: {
      }
    },
    {
      path: '/projects',
      component: () => import('../views/project/index.vue'),
      meta: {
        title: locale.global.t('websiteTitle.projects'),
        description: locale.global.t('projects.index.briefIntroduction1'),
      }
    },
    {
      path: '/project/:id',
      props: true,
      component: () => import('../views/project/detail.vue'),
      meta: {
        lazyStats: true,
      }
    },
    {
      path: '/information/:navigator?',
      props: true,
      component: () => import('../views/information/index.vue'),
      meta: {
        title: false,
        // 信息公开页面只有中文版
        description: '包括基金会的信息透明公开，保证基金会的公益属性和慈善属性，还包含开源行业知识的开源共享',
        lazyStats: true,
      }
    },
    {
      path: '/cooperation/:navigator?',
      props: true,
      component: () => import('../views/cooperation/index.vue'),
      meta: {
        title: locale.global.t('websiteTitle.cooperation'),
        description: `${locale.global.t('cooperation.title')} ${locale.global.t('cooperation.titleSmall')} ${locale.global.t('cooperation.titleDescription')}`,
      }
    },
    {
      path: '/journalism',
      redirect: '/journalism/index',
    },
    {
      path: '/journalism/index/:categoryId?/:offset?',
      component: () => import('../views/journalism/index.vue'),
      props: true,
      meta: {
        title: false,
        lazyStats: true,
      }
    },
    // 普通的新闻详情页
    {
      path: '/journalism/detail/:id',
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'detail' }),
      component: () => import('../views/journalism/detail.vue'),
      meta: {
        title: false,
        lazyStats: true,
      }
    },
    // 用于显示文章类型的新闻
    {
      path: '/journalism/article/:id',
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'article' }),
      component: () => import('../views/journalism/detail.vue'),
      meta: {
        title: false,
        lazyStats: true,
      }
    },
    {
      path: '/about',
      component: () => import('../views/about/index.vue'),
      meta: {
        title: locale.global.t('websiteTitle.about'),
      }
    },
    // {
    //   path: '/about/council/fengguanlin',
    //   component: () => import('../views/about/fengguanlin.vue'),
    //   meta: {
    //     title: '冯冠霖',
    //   }
    // },
    {
      path: '/about/council/chengxiaoming',
      component: () => import('../views/about/chengxiaoming.vue'),
      meta: {
        title: '程晓明',
      }
    },
    {
      path: '/privacy-policy',
      component: () => isZh ? import('../views/other-pages/privacy-policy/index-zh.vue') : import('../views/other-pages/privacy-policy/index-en.vue'),
      meta: {
        layout: LayoutSimple,
        title: locale.global.t('websiteTitle.privacyPolicy'),
      }
    },
    {
      path: '/terms',
      component: () => isZh ? import('../views/other-pages/terms/index-zh.vue') : import('../views/other-pages/terms/index-en.vue'),
      meta: {
        layout: LayoutSimple,
        title: locale.global.t('websiteTitle.terms'),
      },
    },
    {
      path: '/brand-guide',
      component: () => isZh ? import('../views/other-pages/brand-guide/index-zh.vue') : import('../views/other-pages/brand-guide/index-en.vue'),
      meta: {
        layout: LayoutSimple,
        title: locale.global.t('websiteTitle.brandGuide'),
      },
    },
    {
      path: '/contact',
      component: () => isZh ? import('../views/other-pages/contact/index-zh.vue') : import('../views/other-pages/contact/index-en.vue'),
      meta: {
        layout: LayoutSimple,
        title: locale.global.t('websiteTitle.contact'),
      },
    },
    {
      path: '/law',
      redirect: '/law/translate',
      component: () => import('../views/law/index.vue'),
      meta: {
        title: false,
      },
      children: [
        { path: 'translate', component: () => import('../views/law/sub/translate.vue') },
        { path: 'salon', component: () => import('../views/law/sub/salon.vue') },
        { path: 'lecture', component: () => import('../views/law/sub/lectures.vue') },
        { path: 'database', component: () => import('../views/law/sub/database.vue') },
      ]
    },
    {
      path: '/events',
      component: () => import('../views/events/index.vue'),
      meta: {
        title: locale.global.t('websiteTitle.events'),
      }
    },
    {
      path: '/unsubscribe',
      component: () => import('../views/unsubscribe/index.vue'),
      props: (route: RouteLocation) => ({
        email: route.query.email,
        name: route.query.name,
      }),
      meta: {
        title: locale.global.t('websiteTitle.unsubscribe'),
      },
    },

    {
      path: '/insight',
      redirect: '/insight/index',
    },
    {
      path: '/insight/index',
      component: () => import('../views/insight/index.vue'),
      meta: {
        title: locale.global.t('websiteTitle.insight'),
      }
    },
    {
      path: '/insight/issue/:id',
      component: () => import('../views/insight/issue.vue'),
      props: true,
      meta: {
        title: false,
        lazyStats: true,
      }
    },
    {
      path: '/insight/article/:id',
      component: () => import('../views/insight/article.vue'),
      props: true,
      meta: {
        title: false,
        lazyStats: true,
      }
    },

    // 404 路由，业务路由都要放在这前面
    {
      path: '/:pathMatch(.*)*',
      alias: '/404',
      component: () => import('../views/common/404.vue'),
      meta: {
        title: '404 Not Found',
      }
    },
  ];

  const router = _createRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      else {
        return { top: 0 };
      }
    },
  });

  router.beforeEach((to) => {
    if (!import.meta.env.SSR) {
      const store = useAppStore();

      if (to.meta.title !== false) {
        store.setTitle(to.meta.title as string);
      }
    }
  });

  router.afterEach((to) => {
    if (!import.meta.env.SSR) {
      // 统计埋点
      if (window._oafs) {
        if (to.meta.lazyStats !== true) {
          setTimeout(() => {
            window._oafs.setDefault('pageId', document.title);
            window._oafs.pv();
          });
        }
      }
    }
  });

  return router;
}
