'404':
  notFound: 页面未找到
  goBack: 返回首页
languageName: 简体中文
websiteKeywords: >-
  开放原子开源基金会, 全球开源事业, 非营利机构, 阿里巴巴, 百度, 华为, 浪潮, 360, 腾讯, 招商银行, 开源项目, 科技公益, 共建共治共享,
  国际开源社区, 行业协作, 募集资金, 专项资助, 宣传推广, 教育培训, 学术交流, 国际合作, 开源生态建设, 咨询服务, 法务协助, 资金支持,
  技术支撑, 开放治理, 可持续发展
websiteDescription: >-
  开放原子开源基金会是一家成立于2020年6月的非营利机构，由阿里巴巴、百度、华为、浪潮、360、腾讯、招商银行等多家领先科技企业联合发起。致力于推动全球开源事业发展，通过开发者为本的开源项目孵化平台和科技公益性服务，以共建、共治、共享的原则，系统性打造开源开放框架，搭建国际开源社区，提升行业协作效率，赋能千行百业。
websiteTitle:
  default: 开放原子开源基金会
  projects: 开源项目
  cooperation: 合作
  about: 关于我们
  events: 活动概览
  unsubscribe: 取消订阅
  privacyPolicy: 隐私政策
  terms: 网站使用条款
  brandGuide: 品牌使用指南
  contact: 联系方式
  insight: 开源态势洞察
home:
  slogan: 繁荣开源事业 共享开源价值
  more: 了解更多
  bottomBanner:
    title: 向您推荐 AtomGit 一起用代码改变世界！
    view: 点击查看
  exploreProjects:
    allItems: 查看全部项目
    incubate: 孵化
    ourProject: 探索我们的项目
  infoWills:
    title: 我们的愿景
  info:
    numberIntroduce1: 年度开源活动数
    numberIntroduce2: 开源项目数量
    numberIntroduce3: 项目代码总量
    numberIntroduce4: 贡献者数量
  openSourceService:
    title: 开源服务
    introduce1: 开源战略咨询
    introduce2: 开源法律赋能
    introduce3: 开源项目运营
    introduce4: 开源品牌推广
    content: 查看详细服务
  banner:
    title1: 开放原子开源大赛
    title2: 开放原子校源行
    title3: ATOMGIT代码仓库
    title4: 开放原子开发者大会
  welfare:
    title1: 为开源公益
    title2: 献出你的力量
    lawList:
      title1: 公益翻译
      name1: “源译识”
      description1: 通过公开征稿、开放讨论、专家评审的方式，发布公开可用的、可信赖的中文译本。
      name2: “源规律”
      title2: 系列课程
      description2: 面向律师、企业法务及开发者提供开源合规公益系列课程。
      name3: “心寄源”
      title3: 法律沙龙
      description3: 搭建开放透明、交流共享的平台，开启开源法律人才交流新纪元。
    content: 查看全部项目
    topic1:
      title: 开放原子{0}
      titlePrimary: 校源行
      content: '{0}培育开源人才，提升科技创新企业开源社会责任，加速社会开源创新，促进软件产业高质。'
      contentPrimary: 在全国范围开展开放原子校源行活动将从“源头”宣扬开源文化。
    topic2:
      title: 开放原子{0}
      titlePrimary: 开源大赛
      contentPrimary: 开放原子开源基金会发起首届“开放原子全球开源大赛”。
      content: >-
        开放原子开源大赛旨在联合开源组织、企事业单位、高等院校、科研院所、行业组织、投融资机构等多方资源，充分发挥产业链生态上下游的协同能力，基于开源共享、共建共治的原则共同举办。
    lawTitle: 开源{0}
    lawTitlePrimary: 法律
  contributors:
    title: 成为光荣的捐赠人
    donationDetails: 查看捐赠详情
    donationOffunds: 资金捐赠
    donationOfFundsIntroduce: 为开源事业献出你的一份力量！
    donationOfFundsIntroduce1: 共同发展项目，支持和塑造开源生态系统，鼓励孵化项目产生里程碑式的构建、发布和发展社区
    projectDonation: 项目捐赠
    projectDonation1: 欢迎企业、组织或个人捐赠开源项目！
    projectDonation2: 共同发展项目，支持和塑造开源生态系统，鼓励孵化项目产生里程碑式的构建、发布和发展社区
    platinumDonor: 白金捐赠人
    moreDonation: 查看更多捐赠
cooperation:
  title: 成为光荣的捐赠人
  titleSmall: 让我们一起，成为开源时代的创造者！
  titleDescription: >-
    作为中国首家且唯一开源领域的基金会，为了推进开源项目、开源生态的繁荣发展，希望更多科技企业加入，并与其他捐赠人紧密连接，共同建设开源事业。资源支持、专业服务，近距离体验开源领域最佳实践；吸引共建、加快迭代，构筑企业自身开源战略。变成参与者、成为引领者，定义、维护开源行业前沿技术规范标准。中立运营，公开透明，开源开放，繁荣共创，以开发者为本，共享开源价值！
  navigator1: 资金捐赠
  navigator2: 项目捐赠
  navigator3: 合作伙伴
  partner:
    title: 合作伙伴
    searchInputPlaceholder: 请搜索内容...
    searchInputTitle1: 历史搜索
    searchInputTitle2: 推荐搜索
    tableHead1: 序号
    tableHead2: 捐赠人
    tableHead3: 捐赠对象
    tableHead4: 等级
    contributor1: 白金捐赠人
    contributor2: 黄金捐赠人
    contributor3: 白银捐赠人
    contributor4: 开源贡献人
    tableEmpty: 暂无内容
    searchCategoryAll: 全部
    contributorNotice: 注：带*号的捐赠人，捐赠协议正在流程中
    information:
      title: 信息公示
  projectDonate:
    title: 项目捐赠
    description:
      content1: 欢迎企业、组织或个人将开源项目捐赠给开放原子开源基金会进行孵化。
      content2: >-
        开放原子开源基金会是一个中立的托管组织，开放的技术治理、专门的支持人员、专业的导师、大量的支持服务、各种协作机会以及不断成长的充满活力的开发者社区，是开源项目成功孵化、持续发展的重要保障。
    subTitle: 成功进入开放原子开源基金会孵化阶段的开源项目可以获得专业开源服务：
    table:
      head1: 服务分类
      head2: 服务子类
      head3: 具体服务内容
      category1:
        title: 资金服务
        children:
          sub1:
            title: 资金资助
            children:
              content1: 项目资金资助
          sub2:
            title: 资金募集
            children:
              content1: 项目资金募集
          sub3:
            title: 资金托管
            children:
              content1: 项目资金托管
              content2: 项目财务管理
          sub4:
            title: 项目采购
            children:
              content1: 项目采购服务
          sub5:
            title: 资金审计
            children:
              content1: 项目资金合规审计
      category2:
        title: 治理服务
        children:
          sub1:
            title: 项目治理
            children:
              content1: 项目治理章程模板
              content2: 项目组织架构和角色设置
              content3: 项目协作管理流程模板
              content4: 项目文档管理指引
              content5: 项目贡献规范指引
              content6: 基金会委派执行秘书
              content7: 为工作组提供招聘服务
          sub2:
            title: 合规治理
            children:
              content1: 代码合规分析
              content2: 开源项目合规策略和计划评估
          sub3:
            title: 社区治理
            children:
              content1: 建立社区领导力指引
              content2: 社区用户增长指引
              content3: 社区贡献者激励计划指引
      category3:
        title: 营销服务
        children:
          sub1:
            title: 品牌输出
            children:
              content1: 基金会新媒体矩阵品牌露出
              content2: 参与基金会的宣传
          sub2:
            title: 活动输出
            children:
              content1: 基金会级别大会独立展台
              content2: 基金会级别大会分论坛主办权
              content3: 和基金会联合举办活动
          sub3:
            title: 商标服务
            children:
              content1: 商标设计
              content2: 商标注册
      category4:
        title: 法律服务
        children:
          sub1:
            title: 开源许可证
            children:
              content1: 开源许可证管理和风险评估
          sub2:
            title: 开源知识产权
            children:
              content1: 开源知识产权管理和风险评估
          sub3:
            title: 合同服务
            children:
              content1: 合同审查和专业法律意见
      category5:
        title: 导师服务
        children:
          sub1:
            title: 项目导师
            children:
              content1: 为项目匹配专业的导师
              content2: 提供项目成长的导师计划
              content3: 评估项目的成熟度和健康度
      category6:
        title: 开发者服务
        children:
          sub1:
            title: 工作坊
            children:
              content1: 工作坊活动组织指引
              content2: 工作坊活动策划、组织和执行
          sub2:
            title: 开发者成长计划
            children:
              content1: 开发者成长计划指引
              content2: 开发者成长计划策划、组织和执行
      category7:
        title: 培训服务
        children:
          sub1:
            title: 认证培训
            children:
              content1: 导师认证培训
              content2: 开发者认证培训
      category8:
        title: IT基础设施的开发
        children:
          sub1:
            title: 账户管理
            children:
              content1: 基金会大账号使用及维护
          sub2:
            title: 代码托管
            children:
              content1: Git代码托管管理指引
              content2: Git代码托管平台的CLA/DCO
              content3: Git代码托管平台的CI/CD
          sub3:
            title: 代码合规
            children:
              content1: 代码合规扫描
          sub4:
            title: 官网建设
            children:
              content1: 提供官网设计、开发和运维服务
          sub5:
            title: 其他IT设施
            children:
              content1: 提供论坛、Wiki等IT基础设施的开发
      category9:
        title: 生态拓展服务
        children:
          sub1:
            title: BD支持
            children:
              content1: 基金会BD支持
              content2: 捐赠单位交流
    projectStepTitle: 项目捐赠流程
    projectStepList:
      title1: 签署承诺函
      desc1: 签署保密承诺函/保密协议
      title2: 提交材料
      desc2: 捐赠方提交项目介绍材料
      title3: 材料审查
      desc3: 基金会进行项目介绍材料的审查
      title4: 项目评审
      desc4: TOC进行项目孵化评审
      title5: 代码复检
      desc5: 对项目代码进行复检
      title6: 签署协议
      desc6: 双方签署捐赠协议
  unSpecialPanel:
    title: 非专项捐赠
    content: >-
      企业、组织或个人可对基金会进行非专项资金捐赠。如捐赠人不限定捐赠资金用于特定活动或项目，捐赠资金将由基金会统筹安排，用于对开源项目基础设施的维护和服务、开放原子商标的保护、以及各种开源赋能计划所需要的支持等符合基金会宗旨的业务。以此推动开源文化的传播，促进开源社区活跃繁荣、保障开源项目可持续发展。
    extraordinaryTitle: 杰出捐赠人
    extraordinaryRule: 不低于人民币 1000 万元/年
    platinumTitle: 白金捐赠人
    platinumRule: 不低于人民币 300 万元/年且承诺连续捐赠不少于3年
    goldTitle: 黄金捐赠人
    goldRule: 不低于人民币 100 万元/年且承诺连续捐赠不少于 2 年
    silverTitle: 白银捐赠人
    silverRule: 不低于人民币 20 万元/年
  welfareDonate:
    title: 资金捐赠
    welfareTab:
      title1: 非专项捐赠
      title2: 专项捐赠
      contentTitle: 资金捐赠流程
    specialTabpanel:
      list1: 企业、组织或个人除可对基金会进行非专项资金捐赠外，还可针对基金会的特定活动、项目或计划进行专项资金捐赠。
      list2: 捐赠人在以此种捐赠方式支持社会公益、履行社会责任的同时，还有机会更近距离、更大程度地参与意向的活动、项目或计划。
    contentList:
      title1: 签订承诺函
      desc1: 捐赠方签订保密承诺函
      title2: 签订协议
      desc2: 双方签订基金会资金捐赠协议或者基金会项目资金捐赠协议并盖章
      title3: 支付捐款
      desc3: 捐赠方支付捐赠款项
      title4: 开具捐赠票据
      desc4: 基金会开具捐赠票据交付捐赠方
      title5: 品牌宣传
      desc5: 基金会对捐赠方品牌进行露出宣传
    telephone: 资金捐赠请联系 电话：010-59258678转8007
    email: 邮箱：sponsorship{'@'}openatom.org
header:
  nav1: 首页
  nav2: 开源项目
  nav3: 合作
  nav4: 关于我们
  nav5:
    title: 开源公益
    children:
      nav1: 开放原子校源行
      nav2: 开放原子开源大赛
      nav3: 开源法律
      nav4: 开源态势洞察
  gotoLegacy: 前往旧版
  nav6:
    title: 开源活动
    children:
      nav1: 开放原子全球开源峰会
      nav2: 开放原子开发者大会
      nav3: 活动概览
about:
  title: 关于我们
  organizationStructure:
    title: 组织架构
    structure1:
      title: 理事会
      content: 负责审议和修改基金会章程等。理事会设理事长、副理事长和秘书长，从理事中选举产生，理事长是基金会的法定代表人。
    structure2:
      title: 技术监督委员会(TOC)
      content: 中立技术决策机构，负责基金会技术相关决策，项目孵化评审等。
    structure3:
      title: 开源安全委员会
      content: 在理事会授权范围内，对开源安全相关的流程、项目、工具、标准、对外合作等工作进行规划和统筹执行。
    structure4:
      title: 秘书处
      content: 秘书处是基金会的执行机构，负责基金会日常运营事务等相关工作，由专职的团队提供法务、营销、社区运营、基础设施建设等支撑工作。下设九个部门：
    department1: 业务发展部
    department2: 运营部
    department3: 法务与知识产权部
    department4: 品牌推广部
    department5: 研发部
    department6: 财务部
    department7: 综合部
    department8: 教育培训部
    department9: 行业研究部
    department10: 开源大赛组委会办公室
  council:
    title: 理事及监事信息
  technicalOversightCommitee:
    title: 技术监督委员会(TOC)
    description: >-
      开放原子开源基金会（以下简称“基金会”）技术监督委员会（Technical Oversight
      Commitee,简称“TOC”）为基金会的开源项目提供技术指导，主要承担以下职责：
    duty1: 为基金会提供全球化的技术视野与指导
    duty2: 审核/批准项目孵化/毕业
    duty3: 制定导师职能，审核，批准导师资源池名单
    duty4: 促进项目间的合作，减少冗余与冲突
    duty5: 接收用户委员会的反馈并协助项目持续完善
    duty6: 促进项目间的生态联系
    duty7: 定义开源相关的常规化实践（原则，流程）
    mentor:
      title: TOC 导师团
      description: 是技术监督委员会（TOC）指导下的外部专家团队，拥有如下权责利：
      duty1: 辅导基金会的项目孵化，每季度提交项目孵化报告
      duty2: 定期参加辅导项目PMC会议并在项目毕业时给出意见
      duty3: 可得基金会颁发导师证书和基金会官网个人品牌展现
      duty4: 参加基金会各项公开活动并作为演讲嘉宾出席TOC活动
      duty5: 可作为基金会大会的分论坛出品人
      duty6: 可应邀出席TOC内部的线下或者线上会议，发表意见
      duty7: 表现突出导师可个人身份经基金会投票选举成为TOC成员
      more: 查看更多
  securityCommitee:
    description: 开源安全委员会为基金会安全相关工作进行规划和统筹执行，主要为以下职责：
    duty1: 为基金会及孵化项目提供开源安全相关建议
    duty2: 制定基金会开源安全工作计划与预算
    duty3: 制定基金会开源安全总则，包括相关的决策机制和流程
    duty4: 发起基金会需要的开源安全类开源项目、工具和平台
    duty5: 开展开源安全相关的国际合作
    duty6: 制定相应的开源安全标准、发布开源安全相关的白皮书
    duty7: 开展开源安全的教育、推广
    duty8: 理事会授权范围内的其他相关工作
  infoAbout:
    title: 关于我们
    briefIntroduction1: 开放原子开源基金会是致力于推动全球开源事业发展的非营利机构，于 2020 年 6 月在北京成立。
    briefIntroduction2: >-
      开放原子开源基金会本着以开发者为本的开源项目孵化平台、科技公益性服务机构的定位，遵循共建、共治、共享原则，系统性打造开源开放框架，搭建国际开源社区，提升行业协作效率，赋能千行百业。
    briefIntroduction3: 目前开放原子开源基金会业务范围主要包括募集资金、专项资助、宣传推广、教育培训、学术交流、国际合作、开源生态建设、咨询服务等业务。
    briefIntroduction4: >-
      开放原子开源基金会专注于开源项目的推广传播、法务协助、资金支持、技术支撑及开放治理等公益性事业，促进、保护、推广开源软件的发展与应用；致力于推进开源项目、开源生态的繁荣和可持续发展，提升我国对全球开源事业的贡献。
  cloudCommunity:
    title: 开放原子云社区
    description: 通过构建开源、开放的云原生技术生态，探索云原生技术创新，推进云原生技术在中国发展，赋能千行百业数字化转型。
    duty1: 探索和构建云原生产业软件开源生态，促进云原生产业发展
    duty2: 提升本社区的技术易用性和可靠性
    duty3: 促进本社区范围内的开源软件推广、使用、宣传、培训等
    duty4: 其它经基金会授权的范围
projects:
  detail:
    title3: 项目社区
    title2: 项目代码
    title1: 下载项目
    number6: PR 数量
    number5: Fork 数量
    number4: Watch 数量
    number3: Contributor 数量
    number2: Star 数量
    number1: Commits 数量
  index:
    title1: 捐赠主体
    title2: 贡献主体
    title3: 项目价值
    title4: 技术特点
    title5: 项目sponsor
    serviceTabList:
      title1: 开源战略咨询
      title2: 开源法律赋能
      title3: 开源项目运营
      title4: 开源品牌推广
      content:
        title1: 开源战略规划
        comment1: 开源产业趋势分析、技术创新、产业标准、生态发展、高阶创新流程等规划
        title2: 开源研发管理咨询
        comment2: 开源研发流程优化、版本管理设计、代码合规指导、开源安全风险指导及咨询
        title3: 开源项目代码合规指导
        comment3: 代码许可证兼容、合规建议，代码版权风险
        title4: 战略执行设计
        comment4: 开源组织设计、社区组织设计、高阶流程设计、开闭源设计、技术标准设计
        title5: 开源合规法律咨询
        comment5: 开源许可证合规咨询、开源许可证兼容性分析等
        title6: 开源合规法律培训
        comment6: 开源治理合规培训、“源规律”开源公益课程等
        title7: 开源法律公益活动
        comment7: |
          开源合规专业论坛、“心寄源”法律沙龙活动、“源译识”开源翻译等
        title8: 开源法律问题研究
        comment8: 开源相关案例研究、开源相关许可证拟制、开源相关标准研究等
        comment9: 完成开源项目认证测试，发布开源项目最新版本，协助开源项目孵化毕业
        comment10: 从开发者、开源项目、开源社区、企业等多维度制定开源治理标准，构建开源软件评估体系
        comment11: 定期举办开发者工作坊、开源技术沙龙&workshop等开源活动，实施开发者激励计划，联动各开源社区多元合作
        comment12: 以代码托管平台AtomGit为核心，提供开源项目运营所需的各项服务和指导
        comment17: 构建官网及全媒体传播渠道
        comment18: 针对开源的技术趋势及项目进展定期营销
        comment19: 从无到有进行开源项目的品牌建设与运营
        comment20: 设计开发与开源文化相关的衍生周边产品
        comment21: 举办开源峰会、开发者大会、开源大赛等活动
        comment22: 组织学校师生参与开源活动，理论和实践相结合
        comment23: 组织策划实施开放原子校源行公益项目，引导学校建开源社团、推广开源文化
        comment24: 甄选校源行开源大使、开源布道师，培养开源导师
        comment25: 制作开源通识课程，组织开源项目贡献开源课程
        comment26: 将基金会的开源项目带入校园共建，培养开源人才
        comment27: 推广开源项目、开源基础理念及开源协作方式
        title17: 构建全媒体传播渠道
        title18: 针对项目定期营销
        title19: 开源项目品牌建设
        title20: 举办开源相关大型活动
        title21: 打造开源文化矩阵
        title9: 开源项目管理服务
        title10: 开源项目治理体系
        title11: 开源技术主题活动
        title12: 开源基础设施
        title22: 开展开源教育活动和实训
        title23: 在高校培养开源人才后备军
        title24: 激发开源布道志愿者
        title25: 系统构建开源通识教程
        title26: 培养开源学生开发者和贡献者
        title27: 开展开源文化启蒙和培养工作
      title5: 开源教育培训
    project1: 开源项目
    project2: 孵化期项目
    briefIntroduction1: 基金会持续构建开源项目发展体系，完善项目战略性布局，孵化培育开源项目
    codeCabin1: 代码仓
    website1: 官网
    details1: 了解详情
    codeCabin2: 代码仓
    website2: 官网
    details2: 了解详情
    project3: 孵化筹备期
    briefIntroduction3: >-
      声明：以下项目名称及部分LOGO来自捐赠方，本网站仅为展示项目信息引用。在项目完成捐赠流程后，将根据基金会相关规则对项目名称及LOGO进行正式确认和发布。
    moreProjects: 查看更多项目
    foundationService: 基金会开源服务
    briefIntroduction4: >-
      XuperCore、AliOS Things、Vearch、UBML、OpenKona、MiniBlink、GreatSQL、linglong
      项目待更名
footer:
  address: 联系我们：北京市北京经济技术开发区科谷一街8号院8号楼22层2201
  content1: 隐私政策
  content2: 网站使用条款
  content3: 品牌使用指南
  content4: 品牌标识下载
  content5: 媒体合作：
  content6: 反馈与建议：
  content7: 供应商自荐：
  content8: 工作机会：
  content9: 资金捐赠：
  content10: 项目捐赠：
  content11: 法律问题：
  content12: 开放原子开源基金会版权所有
  content13: 京公网安备 11030102011597 号
  beian: 京ICP备2020036654号-1
  emailTitle: 订阅开放原子开源基金会，了解最新新闻、活动和更多信息
  emailInfo: >-
    订阅后即表示您同意接收来自开放原子开源基金会的有关新闻、活动等内容的电子邮件。您可以随时取消订阅。有关我们的隐私做法和保护您隐私的承诺的信息，请查看我们的隐私政策。我们不会将您的联系信息出售给第三方。
  subscribeSuccess: 您已成功订阅
  nameError: 姓名不能为空
  emailError: 邮箱格式不正确
  subscribe: 立即订阅
  placegolderName: 姓名
  placegolderEmail: 邮箱
law:
  title1: “源译识”公益翻译
  desc1: >-
    开放原子开源基金会发起的“源译识”（Contransus）社区以在共译中凝聚开源共识为宗旨，对开源相关许可证、域内外司法判决、专业书籍、重点报告等内容进行专业可信的翻译。


    “源译识”欢迎您通过专项投稿、社区讨论、内容推荐、同行评审、专家评述等方式共同参与本社区。源译识出品的译文审定稿请见[总仓库](https://atomgit.com/translation/Contransus)。


    **期待您通过邮件联系 translation{'@'}openatom.io 与我们交流！**
  pepele1:
    title: 教授
    name: 周明辉
  pepele2:
    title: 高工
    name: 卫sir
  pepele3:
    title: 律师
    name: 沈芬
  pepele4:
    title: 教授
    name: 宋雷
  pepele5:
    title: 助理教授
    name: 徐美玲
lectures:
  title1: '"源规律"系列课程'
  desc1: >-
    随着开源在推动创新、促进协作方面的作用日益凸显，开源领域的法律与合规问题日益受到关注。为了满足不同群体对开源的认知和理解需求，开放原子开源基金会推出了“源规律”开源公益系列课程，旨在推动知识产权法律专业人员（如法务、律师等）以及关注开源领域合规问题的人士（如开发者、企业管理者等）了解并参与开源的研究和应用，从而帮助开发者合规地使用和发布开源代码，同时也为企业管理者提供丰富的认知维度。


    “源规律”开源公益系列课程是由基金会支持的长期项目，课程内容及面向将持续更新迭代。我们诚挚欢迎您自荐或推荐对开源有深入或独特的见解的专家参与课程的录制，或向我们提出任何意见或建议。


    特别感谢以下特邀讲师对“源规律”开源公益课程的大力支持：

    邓波儿、耿航、高扬、康正德、适兕、刘果、李思宇、刘伟、李欣博、李欣瞻、孙振华、王荷舒、王永雷、熊岚曦、余甜、周丹丹、张亮等。（按首字母排序）


    **期待您通过邮件联系 legal{'@'}openatom.org 与我们交流！**
  title2: 系列课程全面助力开源知识体系形成
  title3: 七大板块课程内容丰富
  desc3: 补齐开源相关知识，满足开源合规需要，课程内容拆分为七个模块化视频课程，涵盖开源知识的方方面面。
  title4: 形成全面开源知识体系
  desc4: 从开源的起源到开源的法律框架，从开源许可证的相关知识到企业的开源风险及治理，从开源社区的运营到如何贡献代码，全面构建开源的知识体系。
  title5: 开源大咖倾力传授经验
  desc5: 开源布道师适兕老师、抖音集团孙振华老师、统信软件康正德老师、京东李欣博老师、北京允天律所周丹丹律师等数位开源大咖多年经验浓缩精华，开源知识一课掌握。
  title6: 细致讲解开源相关痛点
  desc6: 开源社区如何运转？开源涉及的专利及商标知识有哪些？开源许可证是否能兼容？SCA和CI/CD是什么？开源有没有出口管制？课程直击开源相关难点痛点。
lawData:
  title: 法律资料库
  title1: 全球开源相关司法案例
  desc: 此处汇总了开源相关的司法案例，包括但不限于中国、美国、德国、法国、西班牙等国家的案例，以及开放原子对相关案例的介绍。本模块持续更新中，欢迎您提供线索！
  title2: 法律
  title3: 行政法规
  legalExplain: >-
    本网页将不定期更新，您也可以从 [AtomGit
    平台](https://atomgit.com/OpenAtomFoundation/legal_resources/blob/master/%E5%85%A8%E7%90%83%E5%BC%80%E6%BA%90%E7%9B%B8%E5%85%B3%E5%8F%B8%E6%B3%95%E6%A1%88%E4%BE%8B.md)获得最新内容。


    本案例库参考了：


    * ifrOSS 对德国、法国等欧盟地区案例的整理（请见 [ifrOSS 网站](https://www.ifross.org/?q=urteile)）

    * 书籍：《开源规则：案例、许可证及开源组织》（张平主编、徐美玲执行主编——北京：知识产权出版社，2022.11 ISBN
    978-7-5130-8376-8）

    * 书籍：《商业开源：开源软件许可适用指南（第三版）》（Heather Meeker 著；刘伟译. —— 北京：人民邮电出版社，2023.5 ISBN
    978-7-115-60171-1）


    以上案例由开放原子开源基金会整理，旨在传递与分享开源行业及公益组织相关的法律资料，供您阅读、学习及交流使用。我们仅对已公开资料进行收集、整理与链接。请您注意，某些案例可能需要您在特定网站进行注册后才能获得。该资料不代表开放原子开源基金会观点，也不构成任何建议。
lawSalon:
  title1: '"心寄源"法律沙龙'
  info1: >-
    **心寄源、法同行，携手开启开源法律人才交流的新纪元！**


    近年来中国开源事业日渐兴起，但开源法律人才稀缺且散布于各企业、律所、机构，开源法律人才之间沟通交流的实际需求日益增长。为了尽量减少开源法律界的“孤岛现象”，开放原子开源基金会（下称基金会）法务与知识产权部于2022年组织发起了“心寄源”开源法律沙龙（下称沙龙）。


    沙龙旨在为相关专业人士提供一个畅所欲言、共建共享的交流平台，开启开源法律相关人才交流的新纪元；同时进一步推广开源文化，吸引更多人才加入到开源法律行业中。沙龙活动一般采取闭门的主题演讲和圆桌讨论的形式，线上、线下活动同步进行，时间通常在月末或月初的周五下午，可能根据节假日或主讲嘉宾时间微调。


    沙龙成员资格是按个人身份授予的（尽管很多沙龙成员确实隶属于知名的公司/机构，但这不是成为沙龙成员的必要条件）；除非另有说明，沙龙成员的发言仅代表其发言时的个人观点，而不代表其所在公司/机构的观点或基金会的观点。在当期主讲嘉宾同意的前提下，特定沙龙活动内容可能向社会公众公开发布。


    **期待您通过邮件联系 salonadmin{'@'}openatom.org 与我们交流！**
  title2: 联系我们
  info2: >-
    我们欢迎有开源法律实践经验的企业法务、律师等法律专家成为沙龙成员，分享您熟悉的开源法律理论和实践，跟进业内前沿话题，展开专业、务实的探讨。如果您有意向申请成为沙龙成员，欢迎您发邮件至amytang{'@'}openatom.org联系我们了解更多沙龙情况，索取如何参与沙龙的指引（随附待填写的成员信息备案表、和需要阅读同意的隐私声明及沙龙成员行为指引等材料）。
  title3: 活动照片
  info3: “心寄源”沙龙2023第三期{'|'}总第八期嘉宾合影
  info4: “心寄源”沙龙2023第三期{'|'}总第八期会议现场
events:
  title: 活动概览
  actionTitle: 标题
  actionYear: 年度
  actionCity: 城市
  actionSearch: 查询
  actionReset: 重置
  info1: 这个月
  info2: 开放原子开源基金会共有{0}场活动
  info3: 在如火如荼地开展中
  info4: 期待在{0}与您相会！
  placeholder1: 请输入
  placeholder2: 全部
  notFound: 暂无活动
  regionNotFound: 未找到
  regionAll: 全部
  regionOnline: 线上
  regionEmpty: 无
unsubscribe:
  title: 取消订阅
  desc: 请告诉我们为什么选择取消订阅：
  success: 退订成功
  resubscribe: 订阅成功
  reson1: 我不想再收到这些消息
  reson2: 我从来没有订阅这个邮件列表
  reson3: 邮件内容对我没有帮助
  reson4: 其它
  placeholder: 请输入原因
  desc2: >-
    我们很遗憾您决定取消订阅，但我们感谢您一直以来的支持，我们致力于为用户提供优质的内容，但我们也理解您的需求和选择。如果您日后需要，可以随时{0}我们的服务。如果您对取消订阅有任何疑问或需要任何帮助，请通过
    report{'@'}openatom.org 随时联系我们。感谢您的理解和支持!
  action2: 重新订阅
  desc3: |-
    感谢您订阅我们的最新资讯。您将第一时间收到我们的最新消息，让您更加了解我们的新闻和活动。
    谢谢您的支持！
