'404':
  notFound: Page not found.
  goBack: Go to home
languageName: English
websiteKeywords: >-
  OpenAtom Foundation, non-profit organization, global open source community,
  Beijing, June 2020, technology companies, Alibaba, Baidu, Huawei, Inspur, 360,
  Tencent, China Merchants Bank, open source incubation, collaboration
  framework, international open source community, fundraising, publicity,
  education, international cooperation, open source ecology, consulting
  services, software development, legal assistance, fund support, technical
  support, open governance, sustainable development, China's contribution
websiteDescription: >-
  The OpenAtom Foundation, established in Beijing in June 2020, is a non-profit
  organization dedicated to fostering the global open source community. Founded
  by leading technology companies like Alibaba, Baidu, Huawei, and others, the
  foundation focuses on open source incubation, collaboration, and international
  cooperation. It engages in fundraising, publicity, education, and offers
  consulting services for software development. With a commitment to open
  governance and sustainable development, the OpenAtom Foundation aims to
  enhance China's contribution to the global open source community.
home:
  slogan: >-
    To promote open source collaboration and innovation. To share the fruits of
    open source projects.
  more: Learn More
  bottomBanner:
    title: We recommend AtomGit! Let's change the world with codes!
    view: View
  exploreProjects:
    allItems: View All Projects
    incubate: Incubation
    ourProject: Our Projects
  infoWills:
    title: Our Vision
  info:
    numberIntroduce1: Number of Annual Open Source Events
    numberIntroduce2: Number of Open Source Projects
    numberIntroduce3: Total Volume of Project Code
    numberIntroduce4: Total Number of Contributors
  openSourceService:
    title: Open Source Services
    introduce1: Strategic Consultancy
    introduce2: Legal Support
    introduce3: Project Operations
    introduce4: Marketing & Branding
    content: For More Services
  banner:
    title1: OpenAtom Open Source Competition
    title2: OpenAtom Open Source Talent Training Program
    title3: ATOMGIT Code Repository
    title4: OpenAtom Developer Conference
  welfare:
    title1: Contribute to
    title2: Open source public welfare.
    lawList:
      title1: Translation
      name1: Contransus™ Translation Community
      description1: This Community aims to build open source consensus via translation.
      name2: PrincipOS
      title2: Courses
      description2: >-
        Focusing on open-source, free public welfare courses are designed for
        in-house counsels, lawyers, developers, and managers.
      name3: OSHeart
      title3: Legal Salon
      description3: >-
        To usher in a new era of communications among legal professionals in the
        open-source field.
    content: View All Projects
    topic1:
      title: OpenAtom {0}
      titlePrimary: Open Source Talent Training Program
      content: >-
        {0} It also nurtures open source talents, promotes the open source
        social responsibility of technological innovation-based enterprises,
        accelerates open source innovation among the general public, and
        facilitates high-quality development in the software industry.
      contentPrimary: >-
        Launching nationwide OpenAtom Open Source Talent Training Program
        activities disseminates open source culture from the "source".
    topic2:
      title: OpenAtom {0}
      titlePrimary: Open Source Contest
      contentPrimary: >-
        The first "OpenAtom Open Source Contest" was launched by the OpenAtom
        Foundation.
      content: >-
        {0} It aims to bring together resources from various open source
        organizations, companies, institutions, universities, primary and
        secondary schools, research institutes, industry associations,
        institutional investors and financing agencies.
    lawTitle: Open Source {0}
    lawTitlePrimary: Legal
  contributors:
    title: Make a Donation to OpenAtom!
    donationDetails: View Details
    donationOffunds: Financial Giving
    donationOfFundsIntroduce: Contribute to the open source cause!
    donationOfFundsIntroduce1: >-
      We will work closely with you to incubate your projects, build your open
      source ecosystems, establish milestone frameworks, and create your open
      source communities.
    projectDonation: Project Contribution
    projectDonation1: >-
      Companies, organizations and individuals are welcome to donate open source
      projects!
    projectDonation2: >-
      We will work closely with you to incubate your projects, build your open
      source ecosystems, establish milestone frameworks, and create your open
      source communities.
    platinumDonor: Platinum Sponsorship
    moreDonation: More Donations
cooperation:
  title: Make a Donation to OpenAtom!
  titleSmall: Let's work together to create a better era for open source!
  titleDescription: >-
    As the first and only open source foundation in China, we invite more tech
    companies to join us and work closely with other donors, so as to promote
    the development of open source projects and ecosystems. With our resources
    and professional services, you will be able to experience closely the best
    open source practices. By attracting partners and speeding the iteration,
    you can set up your own open source strategies. You will become a
    participant and a pioneer to define and defend the standards of cutting-edge
    open source technologies. As a neutral organization working transparently on
    the needs of open source developers, we strive to promote open source
    collaboration and innovation, thereby sharing the fruits of open source
    technologies.
  navigator1: Financial Giving
  navigator2: Project Contribution
  navigator3: OpenAtom Partnership
  partner:
    title: OpenAtom Partnership
    searchInputPlaceholder: Search the Site
    searchInputTitle1: Historical Search
    searchInputTitle2: Recommended Search
    tableHead1: Serial Number
    tableHead2: Donor
    tableHead3: Donee
    tableHead4: Level of Sponsorship
    contributor1: Platinum Sponsorship
    contributor2: Gold Sponsorship
    contributor3: Silver Sponsorship
    contributor4: Open Source Contributors
    searchCategoryAll: All
    contributorNotice: ' Note: Donors with * are at the stage of agreement-signing.'
    tableEmpty: Empty
    information:
      title: Information Disclosure
  projectDonate:
    title: Project Contribution
    description:
      content1: >-
        Companies, organizations or individuals are welcome to donate your open
        source projects to OpenAtom for incubation.
      content2: >-
        As a neutral organization providing managed solutions, we support
        successful incubation of open source projects with transparent
        governance, dedicated teams, professional mentors, extensive services,
        various collaboration opportunities, and a growing, vibrant community of
        developers to promote sustainable development.
    subTitle: >-
      We provide professional services to OpenAtom incubated open source
      projects.
    table:
      head1: Categories of Services
      head2: Subcategories
      head3: Items
      category1:
        title: Financial Services
        children:
          sub1:
            title: Financial Support
            children:
              content1: Project Financial Support
          sub2:
            title: Fundraising
            children:
              content1: Project Fundraising
          sub3:
            title: Escrow
            children:
              content1: Project Escrow
              content2: Project Financial Management
          sub4:
            title: Project Procurement
            children:
              content1: Project Procurement Services
          sub5:
            title: Financial Audit
            children:
              content1: Compliance Audit for Projects
      category2:
        title: Governance Services
        children:
          sub1:
            title: Project Governance
            children:
              content1: Template for Project Governance Bylaws
              content2: Project Organizational Structure and Role Settings
              content3: Template for Project Collaboration and Management Processes
              content4: Project Documentation Guidelines
              content5: Project Contribution Guidelines
              content6: Executive Secretary Appointed by OpenAtom
              content7: Recruitment Services Provided by OpenAtom
          sub2:
            title: Compliance Governance
            children:
              content1: Code Compliance Analysis
              content2: >-
                Compliance Strategies, Planning and Evaluation for Open Source
                Projects
          sub3:
            title: Community Governance
            children:
              content1: Guide to Building Community Leadership
              content2: Guide to Community User Growth
              content3: Guide to Community Contributor Incentive Plan
      category3:
        title: Marketing Services
        children:
          sub1:
            title: Brand Promotion
            children:
              content1: Brand Promotion via OpenAtom New Media Matrix
              content2: Participation in OpenAtom Promotion Campaigns
          sub2:
            title: Event Marketing
            children:
              content1: Exclusive Exhibition Booths at OpenAtom Conferences
              content2: Organizing Sub-forums at OpenAtom Conferences
              content3: Co-organizing Events with OpenAtom
          sub3:
            title: Trademark Services
            children:
              content1: Trademark Design
              content2: Trademark Registration
      category4:
        title: Legal Services
        children:
          sub1:
            title: Open Source Licenses
            children:
              content1: Open Source License Management and Risk Assessment
          sub2:
            title: Open Source Intellectual Property (IP)
            children:
              content1: Open Source IP Management and Risk Assessment
          sub3:
            title: Contract Services
            children:
              content1: Contract Review and Professional Legal Advice
      category5:
        title: Mentorship
        children:
          sub1:
            title: Project Mentors
            children:
              content1: Assigning Professional Mentors to Projects
              content2: Providing Mentorship Plans Based on Project Progress
              content3: Evaluating the Maturity and Health of Projects
      category6:
        title: Services for Developers
        children:
          sub1:
            title: Workshops
            children:
              content1: Guide to Workshop Organization
              content2: Event Planning, Organization, and Implementation of Workshops
          sub2:
            title: Developer Grow-up Plan
            children:
              content1: Guide to the Developer Grow-up Plan
              content2: >-
                Event Planning, Organization, and Implementation of the
                Developer Grow-up Plan
      category7:
        title: Training Services
        children:
          sub1:
            title: Certification Training
            children:
              content1: Mentorship Certification Training
              content2: Developer Certification Training
      category8:
        title: IT Infrastructure Development
        children:
          sub1:
            title: Account Management
            children:
              content1: Use and Maintenance of OpenAtom General Account
          sub2:
            title: Code Hosting
            children:
              content1: Guidelines for Git Code Hosting Management
              content2: CLA/DCO on Git Code Hosting Platform
              content3: CI/CD on Git Code Hosting Platform
          sub3:
            title: Code Compliance
            children:
              content1: Code Compliance Analysis.
          sub4:
            title: Website Building
            children:
              content1: Website Design, Development, Operation and Maintenance
          sub5:
            title: Other IT Infrastructure
            children:
              content1: Development of IT Infrastructure (such as forums and wiki pages)
      category9:
        title: Ecosystem Extension
        children:
          sub1:
            title: BD Support
            children:
              content1: BD Support from OpenAtom
              content2: Communication with Our Sponsors
    projectStepTitle: Guide to Project Contribution
    projectStepList:
      title1: Sign a Non-disclosure Letter
      desc1: The donor signs a non-disclosure letter/agreement with OpenAtom.
      title2: Submit the Files
      desc2: The donor submits project files.
      title3: Review the Files
      desc3: OpenAtom reviews project files.
      title4: Review the Project
      desc4: >-
        Technical Oversight Committee (TOC) conducts a further review for
        incubation decisions.
      title5: Review the Codes
      desc5: OpenAtom reviews project codes.
      title6: Sign an Agreement
      desc6: Both parties sign the donation agreement.
  unSpecialPanel:
    title: General Sponsorship
    content: >-
      Enterprises, organizations or individuals can make non-targeted financial
      donations to OpenAtom. If the donor does not limit the donation to
      specific activities or projects, it will be coordinated and used by the
      Foundation for various services that are in line with its purpose,
      including the maintenance and services of the infrastructure of open
      source projects, the protection of OpenAtom's trademarks, and the support
      needed for various open source empowerment programs. With all these, we
      can promote the spread of open source culture, the active and prosperous
      development of open source community, and ensure the sustainable
      development of open source projects.
    extraordinaryTitle: Extraordinary Sponsorship
    extraordinaryRule: 10 million CNY/year (minimum)
    platinumTitle: Platinum Sponsorship
    platinumRule: 3 million CNY/year (minimum) for no less than 3 consecutive years
    goldTitle: Gold Sponsorship
    goldRule: 1 million CNY/year (minimum) for no less than 2 consecutive years
    silverTitle: Silver Sponsorship
    silverRule: 200,000 CNY/year (minimum)
  welfareDonate:
    title: Financial Giving
    welfareTab:
      title1: General Sponsorship
      title2: Targeted Sponsorship
      contentTitle: Guide to Financial Giving
    specialTabpanel:
      list1: >-
        In addition to general financial donations, companies, organizations, or
        individuals are also welcome to make targeted ones to OpenAtom's
        specific events, projects, or programs.
      list2: >-
        While supporting public welfare undertakings and fulfilling your social
        responsibilities, you will also have the opportunity to get more
        involved in the event, project, or program you are interested in.
    contentList:
      title1: Sign a Non-disclosure Letter
      desc1: The donor signs a non-disclosure letter with OpenAtom.
      title2: Sign an Agreement
      desc2: >-
        Both parties sign the donation agreement (funding/project) with their
        stamps on it.
      title3: Make the Donation
      desc3: The donor makes the donation.
      title4: Invoice the Donor
      desc4: OpenAtom issues a donation receipt to the donor.
      title5: Brand Promotion
      desc5: OpenAtom promotes the donor's brand exposure.
    telephone: 'For financial giving, please contact:   TEL: 010-59258678*8007 '
    email: ' Email: sponsorship{''@''}openatom.org '
header:
  nav1: Home
  nav2: Open Source Projects
  nav3: Cooperation
  nav4: About Us
  nav5:
    title: Open Source Public Welfare
    children:
      nav1: OpenAtom Open Source Talent Training Program
      nav2: OpenAtom Open-Source Contest
      nav3: Open Source Legal
  gotoLegacy: Visit Old Website
  nav6:
    children:
      nav3: Events
about:
  title: About Us
  organizationStructure:
    title: Structure
    structure1:
      title: The Board of Directors
      content: >-
        The Board of Directors reviews and amends the bylaws of the Foundation,
        among other duties. The board chair, vice chairs, and secretary-general
        are elected and appointed from board members. The board chair is the
        legal representative of OpenAtom.
    structure2:
      title: Technical Oversight Committee (TOC)
      content: >-
        As a neutral technical decision-making body, TOC is responsible for
        making tech-related decisions and evaluating incubation projects, among
        others.
    structure3:
      title: Open Source Security Committee
      content: >-
        Authorized by the Board of Directors, the Open Source Security Committee
        is responsible for the planning, coordinating and implementation of open
        source security-related processes, projects, tools, standards, external
        cooperation and other work.
    structure4:
      title: Secretariat
      content: >-
        As the executive body of OpenAtom, the Secretariat manages daily
        operations of the Foundation, with a dedicated team providing a range of
        support concerning legal affairs, marketing, community operations, and
        infrastructure construction. It consists of nine departments:
    department1: Department of Business Development
    department2: Operations Department
    department3: Department of Legal Affairs and Intellectual Property
    department4: Branding Department
    department5: R&D Department
    department6: Finance Department
    department7: Department of General Affairs
    department8: Education Department
    department9: Research Department
    department10: Open Source Competition Organizing Committee
  council:
    title: Board Members
  technicalOversightCommitee:
    title: Technical Oversight Committee (TOC)
    description: >-
      At the OpenAtom Foundation (OpenAtom), the Technical Oversight Committee
      (TOC) provides technical guidance for the Foundation's open source
      projects. It undertakes duties including:
    duty1: >-
      Providing technical vision and guidance to the Foundation from a global
      perspective.
    duty2: >-
      Reviewing and approving the incubation and graduation of open source
      projects.
    duty3: >-
      Delegating duties to mentors, examining and approving the list of the
      mentor pool.
    duty4: Promoting cross-project collaboration to avoid redundancy and conflicts.
    duty5: >-
      Receiving feedback from the Users' Committee to enable continuous
      improvement of the projects.
    duty6: Facilitating the formation of ecological connectivity across projects.
    duty7: >-
      Defining open source-related routine practices (such as principles and
      procedures).
    mentor:
      more: View More
  securityCommitee:
    description: >-
      The Open Source Security Committee is responsible for the planning,
      coordinating and implementation of the Foundation's security-related work.
      Its duties include:
    duty1: >-
      Offering advice on open source security to the Foundation and its
      incubation projects.
    duty2: Making the Foundation's work plan and budget of open source security.
    duty3: >-
      Establishing the Foundation's general rules and principles for open source
      security, including relevant decision-making mechanisms and processes.
    duty4: >-
      Initiating open source security-related projects, tools, and platforms
      needed by the Foundation.
    duty5: Engaging in international cooperation related to open source security.
    duty6: >-
      Developing relevant standards for and publishing white papers on open
      source security.
    duty7: Carrying out the education and promotion of open source security.
    duty8: Finishing other relevant work authorized by the Board of Directors.
  infoAbout:
    title: About Us
    briefIntroduction1: >-
      The OpenAtom Foundation is a non-profit organization dedicated to
      promoting the development of the global open source community. It was
      founded in Beijing in June 2020.
    briefIntroduction2: >-
      The OpenAtom Foundation is committed to being a developer-oriented open
      source project incubation platform as well as a technology public welfare
      service organization. It follows the principles of co-construction,
      co-governance, and sharing, systematically builds an open and
      collaborative framework, establishes an international open source
      community, facilitates industry collaboration, and empowers various
      industries.
    briefIntroduction3: >-
      Currently, the OpenAtom Foundation mainly engages in fundraising, special
      funding, publicity and promotion, education and training, academic
      exchange, international cooperation, open source ecology construction, and
      consulting services.
    briefIntroduction4: >-
      The OpenAtom Foundation focuses on promoting and protecting the
      development and application of open source software through public welfare
      activities such as the promotion of open source projects, legal
      assistance, fund support, technical support, and open governance. It is
      committed to promoting the prosperity and sustainable development of open
      source projects and open source ecology, and enhancing China's
      contribution to the global open source community.
  cloudCommunity:
    title: OpenAtom Cloud Community
    description: >-
      By building an open-source and open cloud native technology ecosystem,
      exploring cloud native technology innovation, promoting the development of
      cloud native technology in China, and empowering the digital
      transformation of various industries.
    duty1: >-
      Explore and build an open-source ecosystem of cloud native industry
      software to promote the development of cloud native production industry.
    duty2: Improve the technical usability and reliability of our community.
    duty3: >-
      Promote the promotion, use, promotion, and training of open source
      software within this community.
    duty4: Other authorized scope by the foundation.
projects:
  detail:
    title3: Project Communities
    title2: Project Codes
    title1: Download Projects
    number6: PRs
    number5: Forks
    number4: Watches
    number3: Contributors
    number2: Stars
    number1: Commits
  index:
    title1: Donor(s)
    title2: Contributor(s)
    title3: Project Values
    title4: Technical Features
    title5: Project Sponsor(s)
    serviceTabList:
      title1: Strategic Consultancy
      title2: Legal Support
      title3: Project Operations
      title4: Marketing & Branding
      content:
        title1: Open Source Strategic Planning
        comment1: >-
          Offering strategic planning in open source industry trends analysis,
          technological innovation, industry standards, ecosystem development,
          and advanced innovation processes.
        title2: Consulting on Open Source R&D and Management
        comment2: >-
          R&D process optimization, version control, code compliance, and open
          source risks.
        title3: Compliance Guidance for Open Source Codes
        comment3: >-
          License compatibility, license compliance, and copyright infringement
          risks.
        title4: Strategy Implementation Design
        comment4: >-
          The structure of open source organizations and communities, the design
          of high-level processes, the design of open source and closed source
          frameworks, and the development of technical standards.
        title5: Open Source Compliance Legal Consultation
        comment5: >-
          Open source license compliance advice, open source license
          compatibility analysis, etc.
        title6: Open Source Compliance Legal Training
        comment6: >-
          Training on open source governance compliance, the Yuanguilv (Rules of
          Open Source) lectures, etc.
        title7: Charitable Legal Services and Activities
        comment7: >-
          Charitable legal services and activities: professional forums on open
          source compliance, Xinjiyuan (Legal Issues of Open Source) seminars,
          etc.
        title8: Legal Research
        comment8: >-
          Open source case studies, open source license drafting, open source
          standardization, etc.
        comment9: >-
          Achieving open source project certification tests, releasing the
          latest versions of open source projects, and assisting in the
          incubation and graduation of open source projects.
        comment10: >-
          Formulating open source governance standards from various dimensions
          such as developers, open source projects, communities, and
          enterprises, building an open source software evaluation framework.
        comment11: >-
          Regularly hosting developer workshops, open source technology salons,
          workshops, and other open source events, implementing developer
          incentive plans, and fostering diverse collaborations across open
          source communities.
        comment12: >-
          Utilizing the core code hosting platform AtomGit, providing a
          comprehensive range of services and guidance necessary for the
          operation of open source projects.
        comment17: Creating official websites and omnimedia communication channels.
        comment18: >-
          Regular marketing for open source technology trends and project
          progress.
        comment19: Brand building and operation for open source projects from zero.
        comment20: Design and development of products related to open source culture.
        comment21: >-
          Hosting open source summits, developer conferences, open source
          contests, and similar events.
        comment22: >-
          Engaging students and educators in open source activities, integrating
          theory with practical application.
        comment23: >-
          Organizing and implementing the "Open Source Way to School" public
          service project, guiding schools in establishing open source clubs and
          promoting open source culture.
        comment24: >-
          Selecting ambassadors and advocates for the "Open Source Way to
          School," cultivating open source mentors.
        comment25: >-
          Creating introductory open source courses and coordinating efforts to
          provide courses on contributing to open source projects.
        comment26: >-
          Integrating the foundation's open source projects into campuses for
          collaborative development and nurturing open source talent.
        comment27: >-
          Promoting open source projects, fundamental open source principles,
          and collaborative open source methodologies.
        title9: Open Source Project Management Services
        title10: Open Source Project Governance Systems
        title11: Open Source Technology-themed Events
        title12: Open Source Infrastructure
        title22: Conducting Open Source Education Activities and Training
        title23: Nurturing a Reserve of Open Source Talent in Tertiary Education
        title24: Enthusiastic Engagement of Open Source Volunteers
        title25: Constructing a Comprehensive Open Source Courses
        title26: Cultivating Student Developers and Contributors in Open Source
        title27: Promoting Open Source Culture and Fundamental Principles
        title17: Establishing a Multimedia Communication Platform
        title18: Regular Marketing for Targeted Projects
        title19: Building Open Source Project Branding
        title20: Hosting Large-Scale Events Related to Open Source
        title21: Creating an Open Source Culture Framework
      title5: Open Source Education and Training
    project1: Open Source Projects
    project2: Projects in Incubation
    briefIntroduction1: >-
      The OpenAtom Foundation is dedicated to building a sustainable ecosystem
      for open source project development, enhancing strategic project layout,
      and fostering the cultivation of open source projects.
    codeCabin1: Code Repository
    website1: Official Website
    details1: View More Details
    codeCabin2: Repository
    website2: Website
    details2: View More Details
    project3: Incubation Preparatory Period
    briefIntroduction3: >-
      The following project names and some logos are provided by the donors.
      This website only displays project information for reference. After the
      completion of the donation process, the project names and logos will be
      formally confirmed and published according to the relevant rules of the
      foundation.
    moreProjects: View More Projects
    foundationService: OpenAtom Open Source Services
    briefIntroduction4: >-
      Projects including XuperCore, AliOS Things, Vearch, UBML, OpenKona,
      MiniBlink, GreatSQL and Linglong might be renamed
footer:
  address: >-
    Contact Us: 2201, 22nd Floor, Building 8, No. 8 Kegu 1st Street, Beijing
    Economic and Technological Development Zone, Beijing
  content1: Privacy Policy
  content2: Website Terms of Use
  content3: Brand Usage Guidelines
  content4: Download OpenAtom Foundation Logos
  content5: 'Media Cooperation: '
  content6: 'Feedback and Suggestions: '
  content7: 'Looking for Suppliers: '
  content8: 'Career Opportunities: '
  content9: 'Financial Giving: '
  content10: 'Project Contribution: '
  content11: 'Legal Issues: '
  content12: Copyright © OpenAtom Foundation. All rights reserved.
  content13: J.G.W.A.B. No. 11030102011597
  beian: J. ICP B. No. 2020036654-1
  placegolderName: name
  placegolderEmail: email
  emailTitle: >-
    Stay Updated with OpenAtom Foundation - Get the Latest News, Events, and
    More!
  emailInfo: >
    By subscribing, you agree to receive emails from OpenAtom Foundation
    regarding news, events, and other content. You can unsubscribe at any time.
    For information about our privacy practices and our commitment to protecting
    your privacy, please review our privacy policy. We do not disclose your
    contact information to third parties.
  subscribeSuccess: You have successfully subscribed
  nameError: Please fill in your name
  emailError: Invalid email format
  subscribe: Subscribe now
websiteTitle:
  about: About Us
  cooperation: Cooperation
  projects: Open Source Projects
  default: OpenAtom Foundation
  unsubscribe: unsubscribe
  events: Events
  privacyPolicy: Privacy Policy
  terms: Website Terms of Use
  brandGuide: Brand Usage Guidelines
  contact: Contact Details
lawSalon:
  title1: OSHeart Legal Salon
  info1: >-
    **with Heart on Open-source, and Legal Compliance in Mind, Together, We
    Usher in a New Era of Communications among Legal Professionals in the
    Open-source Field!**


    China's open-source industry has been flourishing over recent years.
    However, a scarcity of open-source legal talents persists, with
    professionals scattered across various enterprises, law firms, and
    institutions, and there is an ever-growing demand for communication and
    collaboration among open-source legal professionals. To overcome the
    "fragmentation" prevalent in the open-source legal community, the Department
    of Legal Affairs and Intellectual Property of OpenAtom Foundation
    (hereinafter referred to as the Foundation) took the initiative to establish
    the OSHeart Legal Salon (hereinafter referred to as the Salon) in 2022.



    The Salon aims to provide a communication platform for professionals to
    speak freely, with joint contribution and shared benefits, and to
    collectively foster a new era of communications among legal professionals in
    the open-source field. At the same time, the Salon endeavors to further
    promote open-source culture, attracting more talents to embrace the
    open-source legal industry. The Salon's events typically involve keynote
    speeches and roundtable discussions, closed-doored, conducted in person and
    online simultaneously. These sessions are usually scheduled for Friday
    afternoons at the end or beginning of each month, with occasional
    adjustments made to accommodate holidays or keynote speakers' schedule.



    Salon membership is awarded on a personal basis (While some members are
    indeed affiliated with prominent companies or organizations, this does not
    constitute a factor in assessing membership). It is important to note that
    the views expressed by Salon members solely represent their personal
    opinions at the time of speaking and do not reflect the perspective of the
    companies or organizations they work for or the perspective of the
    Foundation. Certain Salon activities may be made available to the general
    public with the consent of the keynote speaker for the certain session.


    **Please feel free to share your opinions or suggestions with us via email
    at salonadmin{'@'}openatom.org!**
  title2: Contact Us
  title3: Event Photos
  info3: >-
    "with Heart on Open-source" Legal Salon Session III in 2023, Session VIII of
    the Series - Group Photo of Participants
  info4: >-
    "with Heart on Open-source" Legal Salon Session III in 2023, Session VIII of
    the Series - Conference Venue Scene
  info2: ' We warmly welcome legal experts such as in-house legal counsels and attorneys with hands-on experience in open-source legal field to join the Salon. As a member, you''ll have the opportunity to share your insights on open-source legal theories and practices, stay updated on cutting-edge industry topics, and engage in professional and practical discussions.If you are interested in becoming a member of the Salon, please don''t hesitate to reach out to us at amytang{''@''}openatom.org to request more information about the Salon and a booklet on how to participate (together with the member information form to be filled out, as well as the Privacy Policy Statement and the Code of Conduct for Salon members that need to be read and consent with).'
law:
  title1: Contransus™ Translation Community
  desc1: >-
    The "Contransus" (源译识) Translation Community, initiated by the OpenAtom
    Foundation, aims to build open source consensus via translation, in the hope
    of developing a widerly-shared open source ecosystem. This community is
    launched to promote better understanding of open source by offering
    professionally-reviewed, publicly available and community-oriented
    translations of various open source resources, primarily into Chinese. The
    community focuses on not only translation of open-source licenses, but also
    of open-source-related judgments, books, key reports, policies, etc.


    Through the [Main Repository](https://atomgit.com/translation/Contransus),
    you are warmly welcome to participate in the Contransus Community by using
    approved translations, filing your translation, sharing your opinion on any
    translation texts, as well as recommending useful resources and expert in
    open source domain.


    **If you have any feedback or suggestions, please feel free to contact us
    via translation{'@'}openatom.io**
  pepele1:
    title: Professor
    name: ZHOU Minghui
  pepele2:
    title: Engineer
    name: WEI Sir
  pepele3:
    title: Counsel
    name: SHEN Fen
  pepele4:
    title: Professor
    name: SONG Lei
  pepele5:
    title: Assistant Professor
    name: XU Meiling
lectures:
  title1: '"PrincipOS"Public Welfare Courses'
  desc1: >-
    As the role of open-source in driving innovation and fostering collaboration
    becomes increasingly prominent, legal/compliance issues in the open-source
    domain are raising significant attention. To address the increasing needs
    for knowledge and understanding of open-soure by various groups, the
    OpenAtom Foundation launched the "PrincipOS" Project for public welfare
    courses focusing on open-source. The project aim to facilitate the
    understanding and paticipating of open-source for IP/legal professionals
    (such as in-house counsels, lawyers, etc.) and individuals interested in
    open-source compliance (such as developers, managers, etc.), thus empowering
    developers to use and release open-source code in compliance, and providing
    managers with a broader perspective.


    The "PrincipOS" public welfare course project is a long-term project
    supported by the OpenAtom Foundation, with ongoing updates in terms of
    contents and audience. We warmly welcome anyone who possess in-depth or
    unique insights into open-source to join us in the project, via either
    self-introduction or recommandation.


    Special thanks are extended to following speakers for their great support :

    Deng Bo'er, Geng Hang, Gao Yang, Kang Zhengde, Kuo Si, Liu Guo, Li Siyu, Liu
    Wei, Li Xinbo, Li Xinzhan, Sun Zhenhua, Wang Heshu, Wang Yonglei, Xiong
    Lanxi, Yu Tian, Zhou Dandan, Zhang Liang, and more (listed in alphabetical
    order).


    **Please feel free to share your opinions or suggestions with us via email
    at legal{'@'}openatom.org !**
  title2: Public Welfare Courses For Comprehensive Open-Source Knowledge System
  title3: ' Rich Contents Based on Seven Modules'
  desc3: >-
    To bridge the gaps and meet compliance needs, course contents are sectioned
    into seven modules, covering various aspects of open-source knowledge.
  title4: Building a Comprehensive Open-Source Knowledge System
  desc4: >-
    From the origins of open-source to its legal framework, from open-source
    licenses to open-source risks and governance in enterprises, and from the
    operation of open-source communities to how to make a contribution, the
    courses help you to build an comprehensive open-source knowledge system.
  title5: Experts Sharing Condensed Experiences
  desc5: >-
    Esteemed experts for open-source, such as open-source evangelist Kuo Si, Sun
    Zhenhua from TikTok, Kang Zhengde from Uniontech, Li Xinbo from JD, and Zhou
    Dandan from Yuntian, etc., all elaborate on condensed and practical
    understanding of open-source.
  title6: Direct Response to Open-source Pain Points
  desc6: >-
    How do open-source communities operate? What is patents and trademarks
    knowledge involved in open-source? Are open-source licenses compatible? What
    are SCA and CI/CD? Are there any export controls related to open-source? The
    courses directly address the pain points in open-source domain.
lawData:
  title: Legal Resource Database
  desc: >-
    This is the collection of free and open source-related and CC-related cases
    decided in various jurisdictions, including China, the United States,
    Germany, France, Spain, etc., arranged by the OpenAtom Foundation. This page
    is continuously updated, and we appreciate any suggestons you may have!
  legalExplain: >-
    This page will not be updated regularly, you can refer to [our repo at
    AtomGit](https://atomgit.com/OpenAtomFoundation/legal_resources/blob/master/%E5%85%A8%E7%90%83%E5%BC%80%E6%BA%90%E7%9B%B8%E5%85%B3%E5%8F%B8%E6%B3%95%E6%A1%88%E4%BE%8B.md)
    for the latest information.


    The collection of cases mentioned above references:


    * ifrOSS's colleciton of cases in Germany, France and other countries in
    European Union (see [ifrOSS website](https://www.ifross.org/?q=urteile));

    * Book: The Open Source Rule: Cases, Licenses, and Open Source Organizations
    (Ping Zhang, Ed., and Meiling Xu, Executive Ed. - Beijing: Intellectual
    Property Publishing House, 2022.11 ISBN 978-7-5130-8376-8);

    * Book: Open Source for Business: A Practical Guide to Open Source Software
    Licensing -- Third Edition (Heather Meeker; Translated by Liu Wei.
    --Beijing: Posts and Telecommunications Press, 2023.5 ISBN
    978-7-115-60171-1).


    The above case collection is prepared by the OpenAtom Foundation. We are
    intended to share legal resources related to the open source industry. We
    only provide link to those publicly available contents. However, the website
    registration may be required for accessing some of the cases. This
    collection itself does not represent suggestions or the official opinions of
    the OpenAtom Foundation.
  title1: Global FOSS-related Judicial Cases
  title2: Law
  title3: Regulations
unsubscribe:
  reson1: I don't want to receive these messages anymore.
  reson2: ' I never subscribed to this mailing list.'
  reson3: The email content is not helpful to me.
  reson4: Other
  title: Unsubscribe
  desc: 'Please tell us why you choose to unsubscribe:'
  placeholder: ' Please enter your reason'
  resubscribe: Subscribed Successfully
  success: Unsubscribed successfully
  desc2: >
    We're sorry that you've decided to unsubscribe, but we appreciate your
    ongoing support. We are committed to providing high-quality content to our
    users, and we also understand your needs and choices. You can {0} at any
    time in case of any needs. If you have any questions or need some help
    regarding cancelling the subscription, please feel free to contact us
    anytime at report{'@'}openatom.org. Thank you for your understanding and
    support!
  action2: Resubscribe
  desc3: >-
    Thank you for subscribing to our latest updates. You will receive our latest
    news first, keeping you informed about our news and events.

    Thank you for your support!
events:
  title: Events
  actionTitle: Title
  actionYear: Year
  actionCity: City
  actionSearch: Search
  actionReset: Reset
  info1: This Month
  info2: Our has a total of{0}events.
  info3: Currently in full swing.
  info4: Looking forward to meeting you in{0}!
  placeholder1: Please enter
  placeholder2: All
  notFound: Event not found
  regionNotFound: Not Found
  regionAll: All
  regionOnline: Online
  regionEmpty: No options
