import 'normalize.css';
import 'virtual:uno.css';
import 'animate.css';

import './assets/main.scss';

import { createSSRApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { createRouter } from './router';
import plugins from './plugins';
import { useAppStore } from '@@home/stores/app';

import 'lazysizes';

export function createApp({ language }: { language?: string } = {}) {
  const app = createSSRApp(App);
  const pinia = createPinia();

  app.use(pinia);

  if (import.meta.env.SSR) {
    // 服务端则从参数中获取当前语言
    const appStore = useAppStore();

    appStore.language = language;
  }
  else {
    // 恢复状态
    if (window.__INITIAL_STATE__) {
      pinia.state.value = window.__INITIAL_STATE__;
    }
  }

  app.use(plugins);

  // 因为多语言的关系，路由一定要在 plugins 初始化后再初始化
  const router = createRouter();
  app.use(router);

  return { app, router, pinia };
}
