<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { COOKIE_KEY_LANGUAGE } from '@@home/utils/constants';

const { availableLocales, locale } = useI18n();

const languages = computed(() => availableLocales.slice().sort((a) => {
  return a === locale.value ? -1 : 1;
}));

const changeLanguage = (lang: string) => {
  Cookies.set(COOKIE_KEY_LANGUAGE, lang, {
    expires: 3650,
    path: '/',
    sameSite: 'Lax',
  });
  window.location.href = '/';
}
</script>

<template>
  <header class="page-header">
    <div class="page-header__container mx-150px h-full flex items-center <2xl:mx-80px">
      <div class="header-logo">
        <RouterLink to="/" class="block h-full w-full">
          <img src="@@home/assets/common/logo-with-title.svg" alt="LOGO" class="block h-full w-full object-contain">
        </RouterLink>
      </div>

      <div class="header-nav ml-45px flex flex-grow">
        <ul class="m-0 flex list-none p-0">
          <li>
            <RouterLink to="/" class="block no-underline">{{ $t('header.nav1') }}</RouterLink>
          </li>

          <li>
            <RouterLink to="/projects" class="block no-underline">{{ $t('header.nav2') }}</RouterLink>
          </li>

          <li>
            <Menu as="div" class="relative" v-slot="{ open, close }">
              <MenuButton class="flex cursor-pointer items-center border-0 bg-transparent p-0 text-brand-normal hover:text-brand-primary" :class="{ 'text-brand-primary': open }">
                {{ $t('header.nav5.title') }}
                <div class="icon i-home-icons:arrow-down ml-8px h-16px w-16px transition-transform" :class="{ 'rotate-180': open }"></div>
              </MenuButton>
              <MenuItems class="absolute left-1/2 z-60 mt-31px rounded-4px bg-white p-16px -translate-x-1\/2">
                <MenuItem>
                  <a href="https://xiaoyuanxing.openatom.cn" target="_blank" class="block whitespace-nowrap text-center leading-24px no-underline">
                    {{ $t('header.nav5.children.nav1') }}
                  </a>
                </MenuItem>
                <MenuItem>
                  <a href="https://competition.atomgit.com/" target="_blank" class="mt-16px block whitespace-nowrap text-center leading-24px no-underline">
                    {{ $t('header.nav5.children.nav2') }}
                  </a>
                </MenuItem>
                <RouterLink to="/law" custom v-slot="{ href, navigate }">
                  <MenuItem as="a" :href="href" class="mt-16px block whitespace-nowrap text-center leading-24px no-underline" @click="(event) => { navigate(event); close(); }">
                    {{ $t('header.nav5.children.nav3') }}
                  </MenuItem>
                </RouterLink>
                <RouterLink to="/insight" custom v-slot="{ href, navigate }" v-if="locale === 'zh-CN'">
                  <MenuItem as="a" :href="href" class="mt-16px block whitespace-nowrap text-center leading-24px no-underline" @click="(event) => { navigate(event); close(); }">
                    {{ $t('header.nav5.children.nav4') }}
                  </MenuItem>
                </RouterLink>
              </MenuItems>
            </Menu>
          </li>

          <li v-if="locale === 'zh-CN'">
            <Menu as="div" class="relative" v-slot="{ open, close } ">
              <MenuButton class="flex cursor-pointer items-center border-0 bg-transparent p-0 text-brand-normal hover:text-brand-primary" :class="{ 'text-brand-primary': open }">
                {{ $t('header.nav6.title') }}
                <div class="icon i-home-icons:arrow-down ml-8px h-16px w-16px transition-transform" :class="{ 'rotate-180': open }"></div>
              </MenuButton>
              <MenuItems class="absolute left-1/2 z-60 mt-31px rounded-4px bg-white p-16px -translate-x-1\/2">
                <RouterLink to="/events" custom v-slot="{ href, navigate }">
                  <MenuItem as="a" :href="href" class="block whitespace-nowrap text-center leading-24px no-underline" @click="(event) => { navigate(event); close(); }">
                    {{ $t('header.nav6.children.nav3') }}
                  </MenuItem>
                </RouterLink>
                <MenuItem>
                  <a href="https://www.openatom.cn/summit/2023/index/main/" target="_blank" class="mt-16px block whitespace-nowrap text-center leading-24px no-underline">
                    {{ $t('header.nav6.children.nav1') }}
                  </a>
                </MenuItem>
                <MenuItem>
                  <a href="https://openatomcon.openatom.cn/" target="_blank" class="mt-16px block whitespace-nowrap text-center leading-24px no-underline">
                    {{ $t('header.nav6.children.nav2') }}
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </li>
          <li v-else>
            <RouterLink to="/events" class="block no-underline">{{ $t('header.nav6.children.nav3') }}</RouterLink>
          </li>

          <li v-if="locale === 'zh-CN'">
            <RouterLink to="/journalism" class="block no-underline">新闻动态</RouterLink>
          </li>

          <li v-if="locale === 'zh-CN'">
            <RouterLink to="/information" class="block no-underline">信息公开</RouterLink>
          </li>

          <li>
            <RouterLink to="/cooperation" class="block no-underline">{{ $t('header.nav3') }}</RouterLink>
          </li>

          <li>
            <RouterLink to="/about" class="block no-underline">{{ $t('header.nav4') }}</RouterLink>
          </li>
        </ul>
      </div>

      <div class="header-tools flex items-center">
        <Menu as="div" class="language-selector relative" v-slot="{ open }">
          <MenuButton class="flex cursor-pointer items-center border-0 bg-transparent p-0 text-brand-normal hover:text-brand-primary" :class="{ 'text-brand-primary': open }">
            <div class="icon i-home-icons:world mr-8px"></div>
            {{ $t('languageName', '', { locale }) }}
            <div class="icon i-home-icons:arrow-down ml-8px transition-transform" :class="{ 'rotate-180': open }"></div>
          </MenuButton>
          <MenuItems class="language-selector-items absolute left-1/2 z-60 mt-32px w-112px p-16px -translate-x-1\/2">
            <MenuItem v-slot="{ active }" v-for="lang in languages" :key="lang">
              <a
                class="language-selector-item block text-center leading-24px no-underline siblings:mt-16px"
                :class="{
                  'text-brand-primary': active || (locale === lang),
                  'text-brand-normal': !(active || (locale === lang)),
                  'font-medium': locale === lang
                }"
                href="javascript:;"
                @click="changeLanguage(lang)"
              >
                {{ $t('languageName', '', { locale: lang }) }}
              </a>
            </MenuItem>
          </MenuItems>
        </Menu>

        <!-- <div class="goto-legacy ml-[40px]">
          <a href="https://legacy.openatom.org" target="_blank" class="block flex items-center bg-left bg-no-repeat no-underline">
            <div class="icon i-home-icons:goto-legacy"></div>
            {{ $t('header.gotoLegacy') }}
          </a>
        </div> -->
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.page-header {
  width: 100%;
  height: 72px;
  color: #2d2d2d;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 -1px 0 0 #e6e7ec;
  transition: all .2s;

  &:hover {
    background: #fff;
    box-shadow: inset 0 -1px 0 0 #e6e7ec, 0 0 10px 0 rgba(0, 0, 0, 0.06);
  }

  .page-header__container {
    .header-logo {
      width: 189px;
      height: 40px;
    }

    .header-nav {
      font-size: 16px;

      li + li {
        margin-left: 40px;
      }

      a {
        color: #2d2d2d;

        &:hover {
          color: #1180ff;
        }

        &:visited:not(:hover) {
          color: #2d2d2d;
        }
      }
    }

    .header-tools {
      font-size: 14px;

      .language-selector {
        .icon {
          width: 16px;
          height: 16px;
        }

        .language-selector-items {
          background-color: #fff;
          border-radius: 4px;

          a {
            &:hover {
              color: #1180ff;
            }

            &:visited:not(:hover) {
              color: #2d2d2d;
            }
          }
        }
      }

      .goto-legacy {
        a {
          color: #2d2d2d;

          &:hover {
            color: #1180ff;
          }

          &:visited:not(:hover) {
            color: #2d2d2d;
          }
        }

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
